import React from 'react';
import '../styles/Card.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';

interface DeveloperCardProps {
  key: number;
  img_src: string;
  name: string;
  description: string;
  role: string;
  commits: number;
  issues: number;
  tests: number;
}

// hello world
function DeveloperCard(props: DeveloperCardProps) {
  return (
      <div className="developer-card" style={{ backgroundColor: theme.colors.primary_green, color: theme.colors.light_text2 }}>
        <img
          src={props.img_src}
          className="card-img-top"
          alt={`${props.name}`}
          style={{ width: '100%', height: '400px', objectFit: 'cover' }}
        />
        <div className="card-body">
          <h5 className="card-title" style={{fontFamily: fonts.fonts.subheader, color: theme.colors.light_text}}>{props.name}</h5>
          <p className="card-description"  style={{fontFamily: fonts.fonts.subheader}}>{props.description}</p>
          <p className="card-text" style={{fontFamily: fonts.fonts.subheader, paddingTop: '15px'}}>Role: {props.role}</p>
          <p className="card-stat" style={{fontFamily: fonts.fonts.subheader}}>Number of Commits: {props.commits}</p>
          <p className="card-stat" style={{fontFamily: fonts.fonts.subheader}}>Number of Issues: {props.issues}</p>
          <p className="card-stat" style={{fontFamily: fonts.fonts.subheader}}>Number of Tests: {props.tests}</p>
        </div>
      </div>
  );
}

export default DeveloperCard;
