import * as React from 'react';
import axios from 'axios';
import '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Card.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import { highlightText } from '../utils/highlight';

interface instance {
  id: number;
  instance: number;
  searchTerm: string;
  name: string;
  img_src: string;
  description: string;
  city_id: number;
  address: string;
  phone: string;
  assistance_type: string;
  facility_type: string;
  vid_src: string;
  website: string;
  related_housing_id: number;
  related_education_id: number;
}

const baseURL = 'https://api.veteranhaven.me/healthcare';
const cityBaseURL = 'https://api.veteranhaven.me/cities';


export default function HealthcareCard(props: instance) {
  const [response, setResponse] = React.useState<instance | null>(null);
  const [cityName, setCityName] = React.useState<string | null>(null);
  const [imgName, setImgName] = React.useState('');

  const extractDescription = (descriptionString: string): string[] => {
    const strippedString = descriptionString.replace(/["[\]]/g, ''); 
    return strippedString.split(',').map(item => item.trim()); 
  };

  React.useEffect(() => {
    axios.get(baseURL + '/' + props.id).then((response) => {
        import(`../assets/health_instance_images/${response.data.img_src}`).then((img) => {
          setImgName(img.default);
          // Do whatever you want with the imported image 'img'
          const descriptionArray = extractDescription(response.data.description);
          const limitedDescription = descriptionArray.slice(0, 3).join(', ');
          const parsedResponse = { ...response.data, description: limitedDescription, img: img.default };
          setResponse(parsedResponse);
      }).catch((error) => {
          console.error('Error importing image:', error);
      });

      axios.get(cityBaseURL + "/" + response.data.city_id)
          .then(cityResponse => {
            setCityName(cityResponse.data.city_name);
          })
          .catch(cityError => { 
            console.log(response.data.city_id)
            console.error('Error fetching city data:', cityError);
          });
    });
  }, [props.id, props.instance]);

  return (
    <div className="card__box">
      <Card
        sx={{
          width: 400,
          height: 520,
          backgroundColor: theme.colors.primary_green,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <CardMedia sx={{ height: 400 }} image={imgName} title="image example" />
        <CardContent sx={{ flex: 1 }}>
          {response && (
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              color={theme.colors.light_text}
              fontFamily={fonts.fonts.subheader}
              fontWeight="600">
               {response?.name ? (
              <span>
                {highlightText(response.name.slice(0, 24), props.searchTerm)}
                {response.name.length > 24 && '...'}
              </span>
            ) : "Name Not Available"}
            </Typography>
          )}
          {response && (
            <div>
              <Typography variant="h6" color={theme.colors.light_text} fontWeight="light" fontFamily={fonts.fonts.subheader}>
              Services: {response?.description ? (
              <span>
                {highlightText(response.description.slice(0, 90), props.searchTerm)}
                {response.description.length > 100 && '...'}
              </span>
            ) : "Visit website or look online for more information about services offered."}
             </Typography>
            </div>
          )}

          {response && (
            <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              City: {highlightText(cityName, props.searchTerm)}
            </Typography>
          )}
          {response && (
            <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              Type of Support: {highlightText(response.assistance_type, props.searchTerm)}
            </Typography>
          )}
          {response && (
            <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              Facility Type: {highlightText(response.facility_type, props.searchTerm)}
            </Typography>
          )}
          {response && (
            <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              Website: {response?.website ? (
              <a href={response.website} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>
                <span>
                  {highlightText(response.website.slice(0, 40), props.searchTerm)}
                  {response.website.length > 40 && '...'}
                </span>
              </a>
            ) : "Not Available"}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.light_text2, color: theme.colors.background_brown2, fontFamily: fonts.fonts.subheader, fontWeight:"900",
            '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.light_text, 
            } }}
            href={`/healthcare/${props.instance}`}>
            Learn More
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900", '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/housing/${response?.related_housing_id}`}>
            Related {cityName} Housing 
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900", '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/education/${response?.related_education_id}`}>
            Related {cityName} Education 
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
