/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface Coordinate {
    latitude: number;
    longitude: number;
}

const TexasMap = ({ coordinates }: { coordinates: Coordinate[] }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current || !coordinates) return;

    const width = 600;
    // const height = 600;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    // Define projection
    const projection = d3.geoAlbersUsa()
      .scale(2400)
      .translate([width / 2 + 100 , 0]);

    // Define path generator
    const path = d3.geoPath().projection(projection);

    // Plot coordinates
    svg.selectAll('.point')
      .data(coordinates)
      .enter().append('circle')
      .attr('class', 'point')
      .attr('cx', d => projection([d?.longitude, d?.latitude])?.[0] ?? null)
      .attr('cy', d => projection([d?.longitude, d?.latitude])?.[1] ?? null)
      .attr('r', 3)
      .style('fill', 'orange');

      // Add title
    svg.append('text')
      .attr('x', width / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .text('Educational Resource Locations in Texas');

    // Load Texas map data
    d3.json('https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_1_states_provinces.geojson')
      .then((data: any) => {
        // Filter data to get Texas geometry
        const texasGeometry = data.features.find((feature: any) => feature.properties.name === 'Texas');

        // Render Texas map
        svg.append('path')
          .datum(texasGeometry)
          .attr('d', path)
          .attr('fill', 'none')
          .attr('stroke', 'black');
    
    
    });
    

  }, [coordinates]);

  return <svg ref={svgRef} width="600" height="600"></svg>;
};

export default TexasMap;
