import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import TexasMap from '../components/TexasMap'
import theme from '../styles/theme';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import fonts from '../styles/fonts';

type Dictionary<T> = {
  [key: string]: T;
};

interface Coordinate {
  latitude: number;
  longitude: number;
}
const Visualizations: React.FC = () => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [hdata, setHousingData] = useState<Dictionary<number>>({});

  const hcRef = useRef<SVGSVGElement>(null);
  const [hcdata, setHealthcareData] = useState<Dictionary<number>>({});
  
  const [edata, setEducationData] = useState<Coordinate[]>([]);


  // const baseURL = 'http://127.0.0.1:5009/';
  const baseURL = 'https://api.veteranhaven.me/';

  useEffect(() => {
    const fetchHousingData = async () => {
      try {
        const response = await axios.get(baseURL + 'housing/visualize/');
        setHousingData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchHealthcareData = async () => {
      try {
        const response = await axios.get(baseURL + 'healthcare/visualize/');
        setHealthcareData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchEducationData = async () => {
      try {
        const response = await axios.get(baseURL + 'education/visualize/');
        setEducationData(response.data['coordinates']);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    

    fetchHousingData();
    fetchHealthcareData();
    fetchEducationData();
}, []);

  useEffect(() => { // Pie chart for housing
    if (!svgRef.current || !hdata) return;

    const margin = { top: 50, right: 150, bottom: 50, left: 50 };
    const width = 700; // Adjust width as needed
    const height = 500; // Adjust height as needed
    const pieWidth = 450;
    const radius = pieWidth / 2;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const data = Object.entries(hdata).map(([category, count]) => ({ category, count }));
    const pie = d3.pie<{ category: string; count: number }>().value(d => d.count);

    const pieChartData = pie(data);
    const arc = d3.arc<d3.PieArcDatum<{ category: string; count: number }>>()
      .innerRadius(0)
      .outerRadius(radius);

    const pieChartGroup = svg.append('g')
      .attr('transform', `translate(${radius + margin.left}, ${radius + margin.top})`);

    pieChartGroup.selectAll('path')
      .data(pieChartData)
      .enter()
      .append('path')
      .attr('d', d => arc(d) as string) // Use arc with PieArcDatum type
      .attr('fill', (_, i) => d3.schemeCategory10[i]);

    const legend = svg.selectAll('.legend')
      .data(pieChartData.map(d => d.data.category))
      .enter().append('g')
      .attr('class', 'legend')
      .attr('transform', (_, i) => `translate(${width - margin.right}, ${height/2 - margin.top + i * 20})`);

    legend.append('rect')
      .attr('width', 18)
      .attr('height', 18)
      .style('fill', (_, i) => d3.schemeCategory10[i]);

    legend.append('text')
      .attr('x', 24)
      .attr('y', 9)
      .attr('dy', '0.35em')
      .style('text-anchor', 'start')
      .text(d => d);

    svg.append('text')
      .attr('x', (width) / 2 + margin.left)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .text('Housing Resource Eligibility by Type');
  }, [hdata]);


  useEffect(() => { // Bar chart for healthcare
    if (!hcRef.current || Object.keys(hcdata).length === 0) return;

    const margin = { top: 50, right: 50, bottom: 50, left: 140 };
    const width = 500;
    const height = 400;

    const svg = d3.select(hcRef.current);

    svg.selectAll('*').remove();

    const x = d3.scaleBand()
      .domain(Object.keys(hcdata))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(Object.values(hcdata)) || 0])
      .nice()
      .range([height, 0]);

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    const chartGroup = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    chartGroup.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    chartGroup.append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    svg.append('text')
      .attr('x', width / 2 + margin.left)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .text('Healthcare Facilities by Type');

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2 - margin.top)
      .attr('y', margin.left / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '14px')
      .text('Count');
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10); // Define a color scale

    chartGroup.selectAll('.bar')
      .data(Object.entries(hcdata) as [string, number][])
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d[0]) || 0)
      .attr('y', d => y(d[1]))
      .attr('width', x.bandwidth())
      .attr('height', d => height - y(d[1]))
      .attr('fill', (_, i) => colorScale(i.toString())); // Use color scale to assign colors
    

  }, [hcdata]);

  return (
    <div className="model__page">
    <h1 className="healthcare__header"  style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20}}>Visualizations</h1>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', height: '55rem' }} >
        <svg ref={svgRef} width={800} height={800}></svg>
      </div>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', height: '55rem' }} >
        <svg ref={hcRef} width={800} height={800}></svg>
      </div>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', height: '55rem' }} >
            <TexasMap coordinates={edata}/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', height: '20rem' }}>
        <h4> </h4>
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <h1 className="healthcare__header" style={{ fontFamily: fonts.fonts.header1, color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20 }}>
          Our Critiques
        </h1>
      </div>
      <Card sx={{ minWidth: 500, maxWidth: 500, backgroundColor: theme.colors.background_brown2, margin: 'auto' }}>
  <CardContent>
    <Typography
      gutterBottom
      variant="h4"
      component="div"
      color={theme.colors.light_text2}
      fontFamily={fonts.fonts.subheader}
      textAlign="center"
    >
      <summary style={{ textDecoration: 'underline' }}>What did we do well?</summary>
      <summary>Our team worked well together and we were good at holding each other accountable when needed. We did a good job of first fully understanding what our target group needs before we started implementing resources for them.</summary>
      <summary style={{ textDecoration: 'underline' }}>What did we learn?</summary>
      <summary>We learned about what specifically veterans struggle with post-service to determine what resources would help Veterans, specifically Texas Veterans, transition back into their civilian lives. Whether it is housing, healthcare, or education + employment.</summary>
      <summary style={{ textDecoration: 'underline' }}>What did we teach each other?</summary>
      <summary>Some of us were pretty experienced with the backend side of things, whether it was setting up data extraction API calls, web scraping, or just simply setting up Docker while some of us had a lot of frontend experience, so it was a good back-and-forth of us exchanging skills and ideas with each other.</summary>
      <summary style={{ textDecoration: 'underline' }}>What can we do better?</summary>
      <summary>Something we could have done better was the way we extracted our images because the quality of our images was not standard amongst all the photos. The way we were getting the images at first was by taking a screenshot of them on Google but that was resulting in very poor image quality so we changed it to image webscraping which definitely made the quality better but it is still not the best.</summary>
      <summary style={{ textDecoration: 'underline' }}>What effect did the peer reviews have?</summary>
      <summary>It helped us see our website from an outsider perspective which helped us come up with designing/styling features that we would not have thought of otherwise.</summary>
      <summary style={{ textDecoration: 'underline' }}>What puzzles us?</summary>
      <summary>Something we want to explore further with our website is how we can customize our resources to more efficiently help different kinds of veterans.</summary>
    </Typography>
  </CardContent>
</Card>
    </div>
  );
};

export default Visualizations;
