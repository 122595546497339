import sujitha_img from '../assets/developer_images/Sujitha_Seenivasan.png';
import rajvi_img from '../assets/developer_images/Rajvi_Shah.png';
import nathan_img from '../assets/developer_images/Nathan_Hernandez.png';
import jacob_img from '../assets/developer_images/Jacob_Lopez.png';
import connor_img from '../assets/developer_images/Connor_Chung.png';

import python_img from '../assets/tools/python.png';
import reactjs_img from '../assets/tools/reactjs.png';
import flask_img from '../assets/tools/flask.png';
import docker_img from '../assets/tools/docker.png';
import awsec2_img from '../assets/tools/awsec2.png';
import bootstrap_img from '../assets/tools/bootstrap.png';
import postman_img from '../assets/tools/postman.png';
import vscode_img from '../assets/tools/vscode.png';
import gitlab_img from '../assets/tools/gitlab.png';

export const text = [
  {
    mission_statement:
      'We aim to create an informative, helpful resource to both educate the general public on major struggles in the veteran community and help Texas Veterans readjust to civilian life',
    intended_users:
      'Our intended users are both Texas veterans looking for resources to help them during their readjustment period and those who are looking to learn more about struggles that the Veteran community faces.',
    disparate_data:
      'Integrating disparate data into our website has been a complex process as we have to first determine which attributes of a dataset are relevant to our use case and also find ways to connect them to other models.'
  }
];

export const contributors = [
  {
    img_src: sujitha_img,
    name: 'Sujitha Seenivasan',
    email: ['sujithaseenivasan@utexas.edu'],
    gitlab_username: 'sujithaseenivasan',
    description:
      "I'm a sophomore CS major at UT Austin with interests in software engineering and machine learning.",
    role: 'Frontend/Backend',
    tests: 22
  },
  {
    img_src: rajvi_img,
    name: 'Rajvi Shah',
    email: ['rutvirajvi@gmail.com','rajvishah@rajvis-macbook-air-2.local', 'rajvishah@wireless-10-145-53-13.public.utexas.edu'],
    gitlab_username: 'rajvi115',
    description:
      "I'm a sophomore at UT Austin studying CS and minoring in Entrepreneurhsip with interests in machine learning and tech startups.",
    role: 'Frontend/Backend',
    tests: 14
  },
  {
    img_src: nathan_img,
    name: 'Nathan Hernandez',
    email: ['nathanjhernandez02@gmail.com', 'nathannatedoghernandez@gmail.com'],
    gitlab_username: 'nathanjhernandez02',
    description:
      'I am a senior CS Major at UT Austin. I am interested in Software Engineering and Software Development and I want to do system level coding or backend.',
    role: 'Backend',
    tests: 16
  },
  {
    img_src: jacob_img,
    name: 'Jacob Lopez',
    email: ['jacoblopez0308@gmail.com'],
    gitlab_username: 'jacobl03',
    description:
      'I am a junior CS Major at UT Austin. My Interest are in software engineering, machine learning, and speech/audio processing',
    role: 'Backend',
    tests: 20
  },
  {
    img_src: connor_img,
    name: 'Connor Chung',
    email: ['connor2jinu@gmail.com', 'connorchungcjc6328@utexas.edu'],
    gitlab_username: 'connor2jinu',
    description:
      'I am a senior CS major at UT Austin minoring in Business.  I have interests in computer graphics and machine learning as well as software engineering.',
    role: 'Backend',
    tests: 15
  }
];

export const tools = [
  { name: 'Python', logo: python_img },
  { name: 'React.js', logo: reactjs_img },
  { name: 'Flask', logo: flask_img },
  { name: 'Docker', logo: docker_img },
  { name: 'AWS EC2', logo: awsec2_img },
  { name: 'Bootstrap', logo: bootstrap_img },
  { name: 'Postman', logo: postman_img },
  { name: 'VS Code', logo: vscode_img },
  { name: 'Gitlab', logo: gitlab_img }
];

export const data_sources = [
  {
    name: 'U.S. Department of Veterans Affairs - VA Health Care',
    link: 'https://www.va.gov/health-care/',
    description: 'Physical and mental health resources for veterans.'
  },
  {
    name: 'Texas Veterans Commission - Veteran Homelessness',
    link: 'https://veteransmentalhealth.texas.gov/homelessness/',
    description: 'Provides locations of veteran relief shelters and other housing resources.'
  },
  {
    name: 'Texas Veterans Commission - Veterans Education Department',
    link: 'https://www.tvc.texas.gov/education/#:~:text=The%20Hazlewood%20Act%20is%20a,of%20higher%20education%20in%20Texas.',
    description: 'Provides information on employment and education resources for veterans.'
  },
  {
    name: 'Google',
    link: 'https://developers.google.com',
    description: 'Provides images, youtube videos, and maps.'
  }
];