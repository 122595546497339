import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../styles/InstancePage.css';
import MapComponent from '../components/MapComponent';
import theme from '../styles/theme';
import fonts from '../styles/fonts';

type paramType = {
  index: string;
};

interface HealthcareData {
  name: string;
  instance: number;
  img_src: string;
  description: string;
  city_id: number;
  address: string;
  phone: string;
  assistance_type: string;
  facility_type: string;
  vid_src: string;
  website: string;
  latitude: string;
  longitude: string;
  related_housing_id: number;
  related_education_id: number;
}

const baseURL = 'https://api.veteranhaven.me/healthcare';
const cityBaseURL = 'https://api.veteranhaven.me/cities';

export default function HealthcareInstance() {
  const { index } = useParams<paramType>();

  const [healthcareData, setHealthcareData] = useState<HealthcareData | null>(null);
  const [cityName, setCityName] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');

  const extractDescription = (descriptionString: string): string[] => {
    const strippedString = descriptionString.replace(/["[\]]/g, '');
    return strippedString.split(',').map((item) => item.trim());
  };

  useEffect(() => {
    axios
      .get(baseURL + '/' + index)
      .then((response) => {
        import(`../assets/health_instance_images/${response.data.img_src}`).then((img) => {
          setImgName(img.default);
          // Do whatever you want with the imported image 'img'
          const descriptionArray = extractDescription(response.data.description);
          const limitedDescription = descriptionArray.slice(0, 3).join(', ');
          const parsedResponse = { ...response.data, description: limitedDescription, img: img.default };
          setHealthcareData(parsedResponse);
      }).catch((error) => {
          console.error('Error importing image:', error);
      });
        axios
          .get(cityBaseURL + '/' + response.data.city_id)
          .then((cityResponse) => {
            setCityName(cityResponse.data.city_name);
          })
          .catch((cityError) => {
            console.error('Error fetching city data:', cityError);
          });
      })
      .catch((error) => {
        console.error('Error fetching healthcare data:', error);
      });
  }, []);

  return (
    <div className="instance__page">
      <img className="instance__img" src={imgName}></img>
      <div className="instance__section" style={{ paddingBottom: '100px' }}>
        <div className="text__container">
          <Typography
            fontWeight="bold"
            paddingTop="1.5rem"
            gutterBottom
            variant="h1"
            fontSize="5rem"
            component="div"
            color={theme.colors.primary_green}
            fontFamily={fonts.fonts.header1}>
            {healthcareData?.name}
          </Typography>
          <Typography  
            variant="h3"
            color="#7C826B"
            textAlign="left"
            fontFamily={fonts.fonts.subheader}>
            Services:{' '}
            {healthcareData?.description
              ? healthcareData?.description
              : 'Visit website or look online for more information about services offered.'}
          </Typography>
          <Typography
            fontSize="3.5rem"
            color={theme.colors.background_brown2}
            fontFamily={fonts.fonts.header1}
            fontStyle="italic">
            Details:
          </Typography>
          <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>City:</span> {cityName}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Address:</span> {healthcareData?.address}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Contact Info:</span> {healthcareData?.phone}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Type of Support:</span> {healthcareData?.facility_type}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Assistance Type:</span> {healthcareData?.assistance_type}
        </Typography>

        </div>
        <div className="card__container">
          <Card sx={{ width: 600, backgroundColor: '#7c826b' }}>
            <CardContent>
              <div style={{ width: '100%', height: '400px' }}>
                {healthcareData && (
                  <MapComponent latitude={healthcareData?.latitude} longitude={healthcareData?.longitude}/>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="footer">
        <Link to={`/housing/${healthcareData?.related_housing_id}`}>
          {' '}
          {cityName} Housing Resources{' '}
        </Link>{' '}
        |
        <Link to={`/education/${healthcareData?.related_education_id}`}>
          {' '}
          {cityName} University and Employment Support
        </Link>
      </div>
    </div>
  );
}
