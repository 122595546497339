import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import splash_img from '../assets/splashop2.png';
import top_ten_industries from '../assets/statistic_images/top_ten_industries.png';
import veteran_suicide_stats from '../assets/statistic_images/veteran_suicide_thoughts_statistics.png';
import veterans_homeless_estimates from '../assets/statistic_images/veterans_homeless_estimates.png';
import housingstat from '../assets/splash_page/housingstat.png';
import employmentstat from '../assets/splash_page/job-loss.png';
import mentalillness from '../assets/splash_page/illness.png';
import '../styles/splash.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import '../styles/Text.css';
import theme from '../styles/theme';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import fonts from '../styles/fonts';
import housing_green from '../assets/splash_page/house-green.png'
import housing_white from '../assets/splash_page/house-white.png'
import education_green from '../assets/splash_page/education-green.png'
import education_white from '../assets/splash_page/education-white.png'
import healthcare_green from '../assets/splash_page/healthcare-green.png'
import healthcare_white from '../assets/splash_page/healthcare-white.png'

function Home() {
  const images = [
    top_ten_industries,
    veteran_suicide_stats,
    veterans_homeless_estimates,
    splash_img
  ];

  const quotes = [
    '“The soldier above all others prays for peace, for it is the soldier who must suffer and bear the deepest wounds and scars of war.” - Douglas MacArthur',
    '“Homeless and at-risk veterans need more than just shelter. We must give them the tools to empower themselves and reclaim the self-worth and dignity which comes from occupying a place in the American dream. It is a dream they fought so hard to defend for the rest of us.” - Maria Cuomo Cole',
    '“As we express our gratitude, we must never forget that the highest appreciation is not to utter words, but to live by them.” - John F. Kennedy'
  ];

  const [newText, setnewText] = useState('');
  const isInitialMount = useRef(true);

  const [housingImage, setHousingImage] = useState(housing_white);
  const [educationImage, setEducationImage] = useState(education_white);
  const [healthcareImage, setHealthcareImage] = useState(healthcare_white);


  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * quotes.length);
    setnewText(quotes[index]);
  }, [quotes]);

  useEffect(() => {
    if (isInitialMount.current) {
      shuffle();
      isInitialMount.current = false;
    } else {
      const intervalID = setInterval(shuffle, 20000);
      return () => clearInterval(intervalID);
    }
  }, [shuffle]);

  return (
    <div className="splash-container" style={{ backgroundColor: theme.colors.background_beige }}>
      <img className="splash_img" src={splash_img} alt="Splash Image" />
      <div className="welcome-text" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text }}>
          <h1 style={{ color: theme.colors.light_text }} > welcome to veteran haven</h1>
      </div>
      <div className="stats-section" style={{ backgroundColor: theme.colors.primary_green }}>
        <div className="title" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text, fontStyle: "italic" }}> Among Texas Veterans,</div>
        <div className="stats-container">
          <div className="statistic">
            <div className="statistic-number" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text }}> 227,590 </div>
            <div className="statistic-description" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.light_text2 }}>live in homes with major housing problems </div>
            <img src={housingstat} alt="User Icon" className="statistic-icon" />
          </div>
          <div className="statistic">
            <div className="statistic-number" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text }}>4.8%</div>
            <div className="statistic-description" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.light_text2 }}>experience unemployment</div>
            <img src={employmentstat} alt="Active User Icon" className="statistic-icon" />
          </div>
          <div className="statistic">
            <div className="statistic-number" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text }}>41%</div>
            <div className="statistic-description" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.light_text2 }}>struggle with mental health issues</div>
            <img src={mentalillness} alt="Engagement Icon" className="statistic-icon" />
          </div>
          </div>
      </div>
      <div className="mission-section" >
      <div className="mission-title" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.background_brown2, fontStyle: "italic"}}> What We Do</div>
        <p className="mission-paragraph" style={{ fontFamily: fonts.fonts.subheader, color: theme.colors.background_brown2 }}>
          Our mission is to empower and guide veterans towards a successful and fulfilling
          post-military life. We are committed to providing comprehensive resources and support,
          spanning education, employment, housing, and more. We aim to serve those who have served
          our nation, ensuring they seamlessly transition to civilian life with access to the tools
          and opportunities necessary for personal and professional growth.
        </p>
        <div className="card-container">
          <Link to="/housing" className="card"
          onMouseOver={() => setHousingImage(housing_green)}
          onMouseLeave={() => setHousingImage(housing_white)}
          >
            <div className="card-content">
              <img
                className="card-img"
                src={housingImage}
                />
              <h3 style={{ fontFamily: fonts.fonts.subheader}}>Housing</h3>
              <p style={{ fontFamily: fonts.fonts.subheader}}>Explore Veteran housing resources in Texas</p>
            </div>
          </Link>
          <Link to="/education"className="card"
           onMouseOver={() => setEducationImage(education_green)}
           onMouseLeave={() => setEducationImage(education_white)}
          >
            <div className="card-content">
            <img
                className="card-img"
                src={educationImage}
                />
              <h3 style={{ fontFamily: fonts.fonts.subheader}}>Education and Employment</h3>
              <p style={{ fontFamily: fonts.fonts.subheader}}>Discover education and employment opportunities</p>
            </div>
          </Link>
          <Link to="/healthcare" className="card"
          onMouseOver={() => setHealthcareImage(healthcare_green)}
          onMouseLeave={() => setHealthcareImage(healthcare_white)}
          >
            <div className="card-content">
            <img
                className="card-img"
                src={healthcareImage}
                />
              <h3 style={{ fontFamily: fonts.fonts.subheader}}>Healthcare</h3>
              <p style={{ fontFamily: fonts.fonts.subheader}}>Find mental and physical health resources</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="text-padder" style={{ color: theme.colors.light_text, backgroundColor: theme.colors.background_brown2 }}>
      <div className="mission-title" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.light_text2, fontStyle: "italic"}}> Learn More</div>
        <p style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.light_text2 }}>
          Researchers from the VA New England MIRECC and the Yale School of Medicine found that 5.6
          percent of more than 300,000 Veterans who were referred to anxiety or PTSD clinics
          experienced homelessness within the one-year time period of the study. The homelessness
          rate for the entire Veteran population is about 3.7 percent over a five-year period.
          Veterans who were unmarried or diagnosed with a drug use disorder were more than twice as
          likely to become homeless.
        </p>
        <p style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.light_text2 }}>
          Student veterans using the Post-9/11 GI Bill are more likely to graduate, to have a higher
          GPA, and to earn academically rigorous degrees in the fields of business, health
          professions, science, technology, engineering, and mathematics. Student veterans were
          found to have a 72% success rate in higher education and average GPAs .40 higher than
          their civilian peers. The most common degree fields for veterans are business (27%) and
          Health & STEM (24%). Nearly two-thirds of student veterans are first generation college
          students.
        </p>
        <Slide cssClass="mission-slide">
        <div className="each-slide-effect">
          <div style={{ backgroundImage: `url(${images[0]})` }}></div>
        </div>
        <div className="each-slide-effect">
          <div style={{ backgroundImage: `url(${images[1]})` }}></div>
        </div>
        <div className="each-slide-effect">
          <div style={{ backgroundImage: `url(${images[2]})` }}></div>
        </div>
      </Slide>
      </div>
      <div className="text-padder">
      <div className="mission-title" style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic"}}> Veteran Stories</div>
      <div className="vet-quote-card">
        <Card sx={{ minWidth: 300, maxWidth: 500, backgroundColor: theme.colors.background_brown2 }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              color={theme.colors.light_text2}
              fontFamily={fonts.fonts.subheader}>
              &quot;It wasn&apos;t the easiest ... We have four children: a teenager who&apos;s
              ready to do their own thing and three young children full of energy. I was always
              making sure the kids were with me, watching them, trying to keep them safe.&quot;
              <div>- U.S. Army Reserve Veteran Sequoyia Hurt</div>
            </Typography>
          </CardContent>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              color={theme.colors.light_text}
              fontFamily={fonts.fonts.subheader}>
              <div>
                Diagnosed with Parkinson&apos;s disease, Ballew said he wasn&apos;t able to access
                full military health care benefits – pointing to an &quot;undesirable
                discharge&quot; from the Marines in 1970.
              </div>
              &quot;I&apos;ve never believed his discharge was right. I always told him that he
              should have got a medical discharge&quot;
              <div>- Barbara Ballew, wife of Steve Ballew, a Marine Veteran</div>
            </Typography>
          </CardContent>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              color={theme.colors.light_text2}
              fontFamily={fonts.fonts.subheader}>
              &quot;I specifically asked ITT Tech before signing up whether their degree was the
              same as any other public 4-year university and was told YES. I found out while
              applying at NYPD, LAPD, Seattle PD and 23 other police departments that NONE of them
              accepted ITT Tech credits. Once I found out that my time and money spent at ITT Tech
              was worthless, I tried to transfer my credits to a community college. I was told I
              have to start completely over as a freshman.&quot;
              <div>- Bryan Babock, U.S. Marine</div>
            </Typography>
          </CardContent>
        </Card>
      </div>
      </div>
      <div className="footer">{newText}</div>
    </div>
  );
}

export default Home;
