import * as React from 'react';

import '@mui/material';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Card.css';
import '../styles/Text.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import { highlightText } from '../utils/highlight';

interface HousingData {
  name: string;
  id: number;
  searchTerm: string;
  instance: number;
  img_src: string;
  description: string;
  city_id: number;
  address: string;
  phone: string;
  hours: string;
  eligibility_requirements: string;
  areas_served: string;
  link: string;
  related_healthcare_id: number;
  related_education_id: number;
}

const baseURL = 'https://api.veteranhaven.me/housing';
const cityBaseURL = 'https://api.veteranhaven.me/cities';

export default function HousingCard(props: HousingData) {
  const [response, setResponse] = React.useState<HousingData | null>(null);
  const [cityName, setCityName] = React.useState<string | null>(null);
  const [imgName, setImgName] = React.useState('');

  const extractDescription = (descriptionString: string): string[] => {
    const strippedString = descriptionString.replace(/["[\]]/g, ''); 
    return strippedString.split(',').map(item => item.trim()); 
  };

  React.useEffect(() => {
    axios.get(baseURL + '/' + props.id).then((response) => {
        import(`../assets/housing_instance_images/${response.data.img_src}`).then((img) => {
          setImgName(img.default);
          // Do whatever you want with the imported image 'img'
          const descriptionArray = extractDescription(response.data.description);
          const limitedDescription = descriptionArray.slice(0, 3).join(', ');
          const parsedResponse = { ...response.data, description: limitedDescription, img: img.default };
          setResponse(parsedResponse);
      }).catch((error) => {
          console.error('Error importing image:', error);
      });

      axios.get(cityBaseURL + "/" + response.data.city_id)
          .then(cityResponse => {
            setCityName(cityResponse.data.city_name);
          })
          .catch(cityError => {
            console.error('Error fetching city data:', cityError);
          });
    });
  }, [props.id, props.instance]);
  return (
    <div className="card__box">
      <Card sx={{ width: 400, height: 520, backgroundColor: theme.colors.primary_green, display: 'flex', flexDirection: 'column' }}>
        <CardMedia 
          sx={{ height: 300 }} 
          image={imgName} 
          title={response?.name} />
        <CardContent sx={{flex: 1}}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            color={theme.colors.light_text}
            fontFamily={fonts.fonts.subheader}
            fontWeight="600">
            {response?.name ? (
          <span>
            {highlightText(response?.name.slice(0, 28), props.searchTerm)}
            {response?.name.length > 50 && '...'}
          </span>
        ) : "Name Not Available"}
          </Typography>
          <Typography variant="h6" color={theme.colors.light_text} fontWeight="light" fontFamily={fonts.fonts.subheader}>
          {highlightText(response?.description.slice(0, 100), props.searchTerm)} ...
          </Typography>
          <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
            City: {highlightText(cityName, props.searchTerm)}
          </Typography>
          <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
          Eligibility: {response?.eligibility_requirements ? (
        <span>
          {highlightText(response?.eligibility_requirements.slice(0, 40), props.searchTerm)}
          {response?.eligibility_requirements.length > 40 && '...'}
        </span>
      ) : "Not Available"}
          </Typography>
          <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
            Phone Number: {highlightText(response?.phone, props.searchTerm)}
          </Typography>
          <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
          Website: {response?.link ? (
          <span>
            {highlightText(response?.link.slice(0, 40), props.searchTerm)}
            {response?.link.length > 40 && '...'}
          </span>
        ) : "Not Available"}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.light_text2, color: theme.colors.background_brown2, fontFamily: fonts.fonts.subheader, fontWeight:"900",
            '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.light_text, 
            } }}
            href={`/housing/${props.id}`}>
            Learn More
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900", '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/healthcare/${1}`}>
            Related {cityName} Healthcare 
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900" , '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/education/${response?.related_education_id}`}>
            Related {cityName} Education 
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
