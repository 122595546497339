import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet'; 
import 'leaflet/dist/leaflet.css';

interface MapComponentProps {
  latitude: string;
  longitude: string;
}

import icon from '../assets/map_marker.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const customMarkerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: markerShadow,
  iconSize: [50, 50], 
  iconAnchor: [12, 41]
});

function MapComponent({ latitude, longitude }: MapComponentProps) {
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    parseFloat(latitude),
    parseFloat(longitude)
  ]);

  useEffect(() => {
    setMapCenter([parseFloat(latitude), parseFloat(longitude)]);
  }, [latitude, longitude]);

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <MapContainer center={mapCenter} zoom={13} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={mapCenter} icon={customMarkerIcon}></Marker>
      </MapContainer>
    </div>
  );
}

export default MapComponent;
