
const theme = {
    colors: {
      primary_green: '#7C826B',
      background_beige: '#EBD1AE',
      background_brown1: '#877978',
      background_brown2: '#91806B',
      light_text: '#F1F0E2',
      light_text2: '#E8D5AA'
    }
};
  
  export default theme;
