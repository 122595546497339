import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../styles/InstancePage.css';
import MapComponent from '../components/MapComponent';
import theme from '../styles/theme';
import fonts from '../styles/fonts';

type paramType = {
  index: string;
};

interface HousingData {
  name: string;
  id: number;
  instance: number;
  img_src: string;
  vid_src: string;
  description: string;
  city_id: number;
  address: string;
  phone: string;
  hours: string;
  eligibility_requirements: string;
  area_served: string;
  type_of_housing: string;
  link: string;
  latitude: string;
  longitude: string;
  related_healthcare_id: number;
  related_education_id: number;
}

const baseURL = 'https://api.veteranhaven.me/housing';
const cityBaseURL = 'https://api.veteranhaven.me/cities';

export default function HousingInstance() {
  const { index } = useParams<paramType>();

  const [housingData, setHousingData] = useState<HousingData | null>(null);
  const [cityName, setCityName] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');

  const extractDescription = (descriptionString: string): string[] => {
    const strippedString = descriptionString.replace(/["[\]]/g, '');
    return strippedString.split(',').map((item) => item.trim());
  };

  useEffect(() => {
    axios
      .get(baseURL + '/' + index)
      .then((response) => {
        import(`../assets/housing_instance_images/${response.data.img_src}`)
          .then((img) => {
            setImgName(img.default);
            // Do whatever you want with the imported image 'img'
            const descriptionArray = extractDescription(response.data.description);
            const limitedDescription = descriptionArray.slice(0, 3).join(', ');
            const parsedResponse = {
              ...response.data,
              description: limitedDescription,
              img: img.default
            };
            setHousingData(parsedResponse);
          })
          .catch((error) => {
            console.error('Error importing image:', error);
          });
        axios
          .get(cityBaseURL + '/' + response.data.city_id)
          .then((cityResponse) => {
            setCityName(cityResponse.data.city_name);
          })
          .catch((cityError) => {
            console.error('Error fetching city data:', cityError);
          });
      })
      .catch((error) => {
        console.error('Error fetching housing data:', error);
      });
  }, []);

  return (
    <div className="instance__page">
      <img className="instance__img" src={imgName}></img>
      <div className="instance__section" style={{ paddingBottom: '100px' }}>
        <div className="text__container">
          <Typography
            fontWeight="bold"
            paddingTop="1.5rem"
            gutterBottom
            variant="h1"
            fontSize="5rem"
            component="div"
            color={theme.colors.primary_green}
            fontFamily={fonts.fonts.header1}>
            {housingData?.name}
          </Typography>
          <Typography
            variant="h3"
            color="#7C826B"
            textAlign="left"
            fontFamily={fonts.fonts.subheader}>
            {housingData?.description}
          </Typography>
          <Typography
            fontSize="3.5rem"
            color={theme.colors.background_brown2}
            fontFamily={fonts.fonts.header1}
            fontStyle="italic">
            Details:
          </Typography>
          <Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>City:</span> {cityName}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Address:</span> {housingData?.address}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Contact Info:</span> {housingData?.phone}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Type of Housing:</span> Government-Aided
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Hours of Operation:</span> {housingData?.hours}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Eligibility Requirements:</span> {housingData?.eligibility_requirements}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Areas Served:</span> {housingData?.area_served}
</Typography>

<Typography
  variant="h4"
  color={theme.colors.background_brown2}
  fontFamily={fonts.fonts.subheader}>
  <span style={{ fontWeight: 'bold' }}>Website:</span>{' '}
  {housingData?.link ? (
    <a
      href={housingData?.link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'underline', color: theme.colors.primary_green }}>
      {housingData?.link}
    </a>
  ) : (
    'Website not provided.'
  )}
</Typography>

        </div>
        <div className="card__container">
          <Card sx={{ width: 600, backgroundColor: '#7c826b' }}>
            <CardContent>
              <div style={{ width: '100%', height: '400px' }}>
                {housingData && (
                  <MapComponent
                    latitude={housingData?.latitude}
                    longitude={housingData?.longitude}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="footer">
        <Link to={`/healthcare/${housingData?.related_healthcare_id}`}>
          {' '}
          {cityName} Healthcare Resources{' '}
        </Link>{' '}
        |
        <Link to={`/education/${housingData?.related_education_id}`}>
          {' '}
          {cityName} University and Employment Support
        </Link>
      </div>
    </div>
  );
}
