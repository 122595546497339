import * as React from 'react';
import axios from 'axios';
import '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../styles/Card.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import { highlightText } from '../utils/highlight';

interface EducationData {
  id: number;
  name: string;
  searchTerm: string;
  instance: number;
  img_src: string;
  city_id: number;
  contribution: string;
  degree: string;
  distance_learning: string;
  funding: string;
  address: string;
  link: string;
  related_housing_id: number;
  related_healthcare_id: number;
}

const baseURL = 'https://api.veteranhaven.me/education';
const cityBaseURL = 'https://api.veteranhaven.me/cities';

export default function EducationCard(props: EducationData) {
  const [response, setResponse] = React.useState<EducationData | null>(null);
  const [cityName, setCityName] = React.useState<string | null>(null);
  const [imgName, setImgName] = React.useState('');


  React.useEffect(() => {
    axios.get(baseURL + '/' + props.id).then((response) => {
      import(`../assets/education_instance_images/${response.data.img_src}`).then((img) => {
        setImgName(img.default);
        setResponse(response.data);
        }).catch((error) => {
          console.error('Error importing image:', error);
      });
      axios.get(cityBaseURL + "/" + response.data.city_id)
          .then(cityResponse => {
            setCityName(cityResponse.data.city_name);
          })
          .catch(cityError => {
            console.error('Error fetching city data:', cityError);
          });
    });
  }, [props.id, props.instance]);
  return (
    <div className="card__box">
      <Card sx={{ width: 400, height: 520, backgroundColor: theme.colors.primary_green, display: 'flex', flexDirection: 'column' }}>
        <CardMedia sx={{ height: 400 }} image={imgName} title="image example" />
        <CardContent sx={{flex: 1}}>
        {response && (
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              color={theme.colors.light_text}
              fontFamily={fonts.fonts.subheader}
              fontWeight="600">
              {response?.name ? (
              <span>
                {highlightText(response.name.slice(0, 24), props.searchTerm)}
                {response.name.length > 24 && '...'}
              </span>
            ) : "Name Not Available"}
            </Typography>
          )}
          {response && (
            <div>
              <Typography variant="h6" color={theme.colors.light_text} fontWeight="light" fontFamily={fonts.fonts.subheader}>
              Services: { "Visit website or look online for more information about services offered."}
              </Typography>
            </div>
          )}

          {response && (
            <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              City: {highlightText(cityName, props.searchTerm)}
            </Typography>
          )}
          {response && (
             <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              Degree Type: {highlightText(response.degree, props.searchTerm)}
            </Typography>
          )}
          
          {response && (
             <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
              Website: {response?.link && response.link !== "None" ? (
              <a href={response.link} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>
                <span>
                  {highlightText(response.link.slice(0, 40), props.searchTerm)}
                  {response.link.length > 40 && '...'}
                </span>
              </a>
            ) : "Website not provided."}
            </Typography>
          )}
           {response && (
             <Typography variant="h6" color={theme.colors.light_text} fontWeight="900" fontFamily={fonts.fonts.subheader}>
               Funding Available for: {highlightText(response.funding, props.searchTerm)}
           </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.light_text2, color: theme.colors.background_brown2, fontFamily: fonts.fonts.subheader, fontWeight:"900",
            '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.light_text, 
            } }}
            href={`/education/${props.id}`}
          >
            Learn More
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900", '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/housing/${response?.related_housing_id}`}>
            Related {cityName} Housing 
          </Button>
          <Button
            size="large"
            sx={{ backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text2, fontFamily: fonts.fonts.subheader, fontWeight:"900", '&:hover': {
              backgroundColor: theme.colors.light_text2, 
              color: theme.colors.background_brown2, 
            } }}
            href={`/healthcare/${response?.related_healthcare_id}`}>
            Related {cityName} Healthcare 
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
