// SortDropDown.tsx

import React from 'react';
// import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
// import { SelectChangeEvent } from '@mui/material';
import fonts from '../styles/fonts';
import theme from '../styles/theme';

interface SortDropDownProps {
  onSortChange: () => void;
}

const SortDropDown: React.FC<SortDropDownProps> = ({ onSortChange }) => {
  const [ascendingOrder, setAscendingOrder] = React.useState(true); // Default to ascending order

  const handleSortChange = () => {
    setAscendingOrder(prevOrder => !prevOrder);
    onSortChange();
  };

  return (
    <div style={{ width: '200px' }}>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <InputLabel id="sort-dropdown-label" style={{ fontFamily: fonts.fonts.subheader, color: theme.colors.primary_green, fontWeight: 900}}>Sort Order</InputLabel>
        <Select
          style={{fontFamily: fonts.fonts.subheader, color: theme.colors.primary_green, fontWeight: 900}}
          labelId="sort-dropdown-label"
          id="sort-dropdown"
          value={ascendingOrder ? 'ascending' : 'descending'}
          onChange={handleSortChange}
        >
          <MenuItem value="ascending">Ascending</MenuItem>
          <MenuItem value="descending">Descending</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default SortDropDown;
