import React, { useState, useEffect } from 'react';
import HousingCard from '../components/HousingCard';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../styles/Card.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import DropDownMenu from '../components/DropDownMenu';
import SortDropDown from '../components/SortDropDown';

export interface HousingData {
  address: string;
  areas_served: string;
  city_id: number;
  description: string;
  eligibility_requirements: string;
  foreign_id: number;
  hours: string;
  img_src: string;
  instance: number;
  latitude: number;
  link: string;
  longitude: number;
  name: string;
  phone: string;
  related_education_id: number;
  related_healthcare_id: number;
  vid_src: string;
}
interface EligibilityTypeMapping {
  [key: string]: string;
}


const baseURL = 'https://api.veteranhaven.me/housing';
const citiesURL = 'https://api.veteranhaven.me/cities';
// const paginationURL = "https://api.veteranhaven.me/housing_page";

// const baseURL = 'http://127.0.0.1:5009/housing';
// const citiesURL = 'http://127.0.0.1:5009/cities'; // Adjust the URL accordingly
// update

function Housing() {
  const [housingData, setHousingData] = useState<HousingData[]>([]);
  const [sortedData, setSortedData] = useState<HousingData[]>([]);
  const [pageData, setPageData] = useState<HousingData[]>([]);
  const [page, setPage] = React.useState(1);
  const [ascendingOrder, setAscendingOrder] = useState(true); // Default to ascending order
  const [filterOptions, setFilterOptions] = useState<{ label: string; items: string[] }[]>([
  ]);
  const [masterFilterList, setMasterFilters] = useState<{ [key: string]: string[] }>({}); // State to hold selected filters

  const [city_list, setCityList] = useState<string[]>([]); // State to hold city list
  // Function to fetch cities
  const get_cities = async () => {
    try {
      const response = await axios.get(citiesURL);
      const cities = response.data['city'];
      setCityList(cities);
      console.log(city_list)
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  
  const eligibilityTypeMapping: EligibilityTypeMapping = {
    "veteran": "VETERAN",
    "anyone": "ANYONE",
    "adult": "ADULTS",
    "income": "LOW INCOME",
    "resident": "RESIDENT",
    "senior": "SENIORS",
    "disab": "DISABLED"
  };
  
  const fillFilterOptions = () => {
    const uniqueCities = Array.from(new Set(housingData.map(item => city_list[item.city_id]))).sort();
    const uniqueEligibilityTypes = Array.from(new Set(housingData.map(item => {
      let type = "OTHER";
      
      // Check for each condition and update type accordingly
      for (const requirement of Object.keys(eligibilityTypeMapping)) {
          if (item.eligibility_requirements.includes(requirement)) {
              type = eligibilityTypeMapping[requirement];
              break;  // Once a match is found, no need to check further
          }
      }
      
      return type;
  })));
    const otherIndex = uniqueEligibilityTypes.indexOf("other");
    if (otherIndex !== -1) {
        uniqueEligibilityTypes.splice(otherIndex, 1);
        uniqueEligibilityTypes.push("other");
    }
    const uniqueContactTypes = Array.from(new Set(housingData.map(item => item.phone !== 'View Website' ? "Yes" : "No") ));
    const uniqueResourceTypes = Array.from(new Set(housingData.map(item => item.link !== '' ? "Yes" : "No") ));
      setFilterOptions([
      { label: 'Cities', items: uniqueCities },
      { label: 'Eligibility', items: uniqueEligibilityTypes },
      { label: 'Has contact', items: uniqueContactTypes },
      { label: 'Has website', items: uniqueResourceTypes }
      
    ]);
  };
  const handleFilterSelection = async (label: string, selectedFilters: string[]) => {
    try {
      console.log(label, selectedFilters);
      setMasterFilters(prevFilters => ({
        ...prevFilters, // Spread the previous state
        [label]: selectedFilters // Add new key-value pair
      }));
    } catch (error) {
      console.error('Error filtering data:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Making Axios call with masterFilterList:', masterFilterList);
        const response = await axios.post(baseURL + '/filter/sort/', { masterFilterList, ascendingOrder});
        
        setSortedData(response.data?.filter || []);
        setPage(1);
      } catch (error) {
        console.error('Error filtering data:', error);
      }
    };
    
    fetchData();
  }, [masterFilterList]);

  const handleSortChange = () => {
    setAscendingOrder(prevOrder => !prevOrder); // Toggle between true and false
    // Sort the data alphabetically by name
    const sorted = [...sortedData].sort((a, b) => {
      if (!ascendingOrder) {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortedData(sorted);
  };

  useEffect(() => {
    fetchData();
    get_cities();
  }, []);

  useEffect(() => {
    fillFilterOptions();
  }, [housingData, city_list]);

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL);
      const instances = response.data?.housing || [];
      setHousingData(instances);
      setSortedData(instances);
    } catch (error) {
      console.error('Error fetching housing data:', error);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const startIndex = (page - 1) * 9;
    const endIndex = startIndex + 9;
    setPageData(sortedData.slice(startIndex, endIndex));
  }, [sortedData, page]);

  return (
    <div className="model__page">
      <h1 className="healthcare__header"  style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20}}>Housing</h1>
      <p className="healthcare__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, marginLeft: 20, marginRight: 20}}>
      This model is dedicated to improving veteran’s access to housing resources. It provides a comprehensive list of affordable housing options for veterans who are struggling to find housing accommodations, alongside the eligibility requirements and contact information for each of the resources.
        </p>
     
      <div className="sort__container">
        <div className="sort__container" style={{fontFamily: fonts.fonts.subheader, color: theme.colors.background_brown2, fontWeight: 900, marginTop: 20, marginLeft: 20}}> Total Instances: {sortedData.length}</div>
      </div>

      <div className="filter-container" style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'center', width: '80%', margin: '0 auto'}}>
      <SortDropDown onSortChange={handleSortChange} />
        {filterOptions.map((options, index) => (
          <DropDownMenu key={index} options={options} onFilterSelection={handleFilterSelection}/>
        ))}
      </div>

      <div className="card__container">
        {pageData.map((instance, index) => (
          <div key={index}>
          <HousingCard key={index} id={instance.instance} searchTerm={''} {...instance} />
          </div>
        ))}
      </div>
      <div className="pagination__container">
      <Stack spacing={2}>
        <Typography variant="h4" fontFamily="Poppins"
        >Page: {page}</Typography>
        <Pagination
          count={Math.ceil(sortedData.length / 9)}
          page={page}
          onChange={handlePageChange}
          sx={{
            '& .MuiPaginationItem-root': {
              fontSize: '1.5rem', 
              minWidth: '30px', 
              minHeight: '30px', 
              margin: '0 5px', 
            },
            '& .MuiPaginationItem-ellipsis': {
              fontSize: '2rem', 
            },
          }}
        />
      </Stack>
      </div>
    </div>
  );
}

export default Housing;
