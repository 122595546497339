import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Housing from './pages/Housing';
import Education from './pages/Education';
import Healthcare from './pages/Healthcare';
import Navbar from './components/Navbar';
import HousingInstance from './pages/HousingInstance';
import EducationInstance from './pages/EducationInstance';
import HealthcareInstance from './pages/HealthcareInstance';
import SearchResults from './pages/SearchResults';
import Visualizations from './pages/Visualizations';
import DeveloperVisualizations from './pages/DeveloperVisualizations';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="Housing" element={<Housing />} />
          <Route path="Education" element={<Education />} />
          <Route path="Healthcare" element={<Healthcare />} />
          <Route path="/housing/:index" element={<HousingInstance />} />
          <Route path="/education/:index" element={<EducationInstance />} />
          <Route path="/healthcare/:index" element={<HealthcareInstance />} />
          <Route path="/search_results" element={<SearchResults />} />
          <Route path="Visualizations" element={<Visualizations />} />
          <Route path="DeveloperVisualizations" element={<DeveloperVisualizations />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
