import React from 'react';
import theme from '../styles/theme';
import fonts from '../styles/fonts';

interface ToolCardProps {
  index: number;
  logo: string;
  name: string;
}

function ToolCard(props: ToolCardProps) {
  return (
      <div className="tool-card" style={{backgroundColor: theme.colors.background_brown2, color: theme.colors.light_text}}>
        <div style={{ padding: '1rem' }}>
          <img
            src={props.logo}
            className="card-img-top"
            alt={`${props.name} Logo`}
            style={{ height: '125px', objectFit: 'contain' }}
          />
        </div>
        <div className="card-body">
          <h5 className="text-center card-title" style={{ fontFamily: fonts.fonts.subheader }}>{props.name}</h5>
        </div>
      </div>
  );
}

export default ToolCard;
