import Highlighter from 'react-highlight-words';
import latinize from 'latinize'; // Make sure you import 'latinize'

export function highlightText(text, query) {
  if (typeof query === 'string') {
    const words = query.split(/\s+/);
    // Sanitize the text using 'latinize'
    const sanitizedText = typeof text === 'string' ? latinize(text) : '';
    return (
      <Highlighter
        highlightStyle={{
          padding: '0',
          backgroundColor: '#ffd54f',
        }}
        searchWords={words}
        textToHighlight={sanitizedText}
        autoEscape={true}
      />
    );
  }

  return text;
}