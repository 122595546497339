// Education.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EducationCard from '../components/EducationCard';
import '../styles/Card.css';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import DropDownMenu from '../components/DropDownMenu';
import SortDropDown from '../components/SortDropDown';
import { text } from '../data/Education_data';

export interface EducationData {
  address: string;
  city_id: number;
  contribution: string;
  degree: string;
  distance_learning: string;
  foreign_id: number;
  funding: string;
  img_src: string;
  instance: number;
  latitude: number;
  link: string;
  longitude: number;
  name: string;
  related_healthcare_id: number;
  related_housing_id: number;
  vid_src: string;
}



const baseURL = 'https://api.veteranhaven.me/education';
const citiesURL = 'https://api.veteranhaven.me/cities'; // Adjust the URL accordingly

// const baseURL = 'http://127.0.0.1:5009/education';
// const citiesURL = 'http://127.0.0.1:5009/cities'; // Adjust the URL accordingly

function Education() {
  const [educationData, setEducationData] = useState<EducationData[]>([]);
  const [sortedData, setSortedData] = useState<EducationData[]>([]);
  const [pageData, setPageData] = useState<EducationData[]>([]);
  const [page, setPage] = useState(1);
  const [ascendingOrder, setAscendingOrder] = useState(true); // Default to ascending order
  const [filterOptions, setFilterOptions] = useState<{ label: string; items: string[] }[]>([
  ]);
  const [masterFilterList, setMasterFilters] = useState<{ [key: string]: string[] }>({}); // State to hold selected filters


  const [city_list, setCityList] = useState<string[]>([]); // State to hold city list
  // Function to fetch cities
  const get_cities = async () => {
    try {
      const response = await axios.get(citiesURL);
      const cities = response.data['city'];
      setCityList(cities);
      console.log(city_list)
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fillFilterOptions = () => {
    const normalizeType = (type: string): string => {
      // Normalize the string: convert to lowercase and remove trailing whitespace
      return type.toUpperCase().trim();
    };
    // const uniqueCities = Array.from(new Set(educationData.map(item => item.city_id.toString()))); // needs to change to city_name
    const uniqueCities = Array.from(new Set(educationData.map(item => city_list[item.city_id]))).sort();
    const uniqueDegreeTypes = Array.from(new Set(educationData.map(item => normalizeType(item.degree))));
    const uniqueResourceTypes = Array.from(new Set(educationData.map(item => item.link !== 'None' ? "Yes" : "No") ));
    const uniqueFundingTypes = Array.from(new Set(educationData.map(item => {
      if (!isNaN(parseFloat(item.funding)) && isFinite(parseFloat(item.funding))) {
          const num = parseFloat(item.funding);
          return num > 1000 ? '>1000' : '<1000';
      } else {
          return item.funding;
      }
  }))).sort((a, b) => {
      // Check if both a and b are numeric strings
      const isNumericA = !isNaN(parseFloat(a)) && isFinite(parseFloat(a));
      const isNumericB = !isNaN(parseFloat(b)) && isFinite(parseFloat(b));
      if (isNumericA && isNumericB) {
          return parseFloat(a) - parseFloat(b);
      }
      else if (isNumericA) {
          return 1;
      }
      else if (isNumericB) {
          return -1;
      }
      else {
          return a.localeCompare(b);
      }
  });
      setFilterOptions([
      { label: 'Cities', items: uniqueCities },
      { label: 'Degree Type', items: uniqueDegreeTypes },
      { label: 'Has website', items: uniqueResourceTypes },
      { label: 'Funding for', items: uniqueFundingTypes }
    ]);
  };
  const handleFilterSelection = async (label: string, selectedFilters: string[]) => {
    try {
      console.log(label, selectedFilters);
      setMasterFilters(prevFilters => ({
        ...prevFilters, // Spread the previous state
        [label]: selectedFilters // Add new key-value pair
      }));
      // Assuming masterFilterList is a state variable
      // const response = await axios.post(baseURL + '/filter/', { masterFilterList });
      // setSortedData(response.data.education);
      // setPage(page);
      
    } catch (error) {
      console.error('Error filtering data:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Making Axios call with masterFilterList:', masterFilterList);
        const response = await axios.post(baseURL + '/filter/sort/', { masterFilterList, ascendingOrder});
        
        setSortedData(response.data?.filter || []);
        setPage(1);
      } catch (error) {
        console.error('Error filtering data:', error);
      }
    };

    fetchData(); // Call fetchData function when masterFilterList changes
  }, [masterFilterList]); // Watching for changes in masterFilterList

  const handleSortChange = () => {
    setAscendingOrder(prevOrder => !prevOrder); // Toggle between true and false
    // Sort the data alphabetically by name
    const sorted = [...sortedData].sort((a, b) => {
      if (!ascendingOrder) {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortedData(sorted);
  };
  

  useEffect(() => {
    fetchData();
    get_cities();
  }, []);

  useEffect(() => {
    fillFilterOptions();
  }, [educationData, city_list]);

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL);
      const instances = response.data?.education || [];
      setEducationData(instances);
      setSortedData(instances);
    } catch (error) {
      console.error('Error fetching education data:', error);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const startIndex = (page - 1) * 9;
    const endIndex = startIndex + 9;
    setPageData(sortedData.slice(startIndex, endIndex));
  }, [sortedData, page]);

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  return (
    <div className="model__page">
      <h1 className="healthcare__header"  style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20}}>Education and Employment</h1>
      <p className="healthcare__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, marginLeft: 20, marginRight: 20}}>{text[0].description}</p>
      <p className="healthcare__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2}}>&nbsp;</p>
      <div className="sort__container" style={{ fontFamily: fonts.fonts.subheader, color: theme.colors.background_brown2, fontWeight: 900, marginLeft: 20, marginRight: 20 }}>
        Total Instances: {sortedData.length}
      </div>
  
      <div className="filter-container" style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'center', width: '80%', margin: '0 auto'}}>
      <SortDropDown onSortChange={handleSortChange} />
        {filterOptions.map((options, index) => (
          <DropDownMenu key={index} options={options} onFilterSelection={handleFilterSelection}/>
        ))}
      </div>
  
      <div className="card__container">
        {pageData.map((instance, index) => (
          <div key={index}>
            <EducationCard key={index} id={instance.instance} searchTerm={''} {...instance} />
          </div>
        ))}
      </div>
  
      <div className="pagination__container">
        <Stack spacing={2}>
          <Typography variant="h4" fontFamily="Poppins">
            Page: {page}
          </Typography>
          <Pagination
            count={Math.ceil(sortedData.length / 9)}
            page={page}
            onChange={handlePageChange}
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: '1.5rem',
                minWidth: '30px',
                minHeight: '30px',
                margin: '0 5px',
              },
              '& .MuiPaginationItem-ellipsis': {
                fontSize: '2rem',
              },
            }}
          />
        </Stack>
      </div>
  
      {/* <button onClick={handleRefresh} style={{ marginTop: '20px' }}>
        Refresh Page
      </button> */}
    </div>
  );
}

export default Education;
