import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import { useNavigate } from 'react-router-dom';
import transparent from '../assets/transparent-logo.png';
import search_icon from '../assets/search-interface-symbol.png';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import { Input } from '@mui/material';

const searchBaseURL = 'https://api.veteranhaven.me/search';

function Navbar() {
  const history = useNavigate();
  const [active, setActive] = useState('nav__menu');
  const [selectedTab, setSelectedTab] = useState('Search Entire Site');
  const [toggleIcon, setToggleIcon] = useState('nav__toggler');
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchHovered, setSearchHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const updateSelectedTab = () => {
    const pathArray = window.location.pathname.split('/');
    let lastPath = pathArray[pathArray.length - 1];
    if (lastPath === '' || lastPath === 'about' || lastPath === 'search_results') {
      lastPath = 'all';
    }

    setSelectedTab(lastPath);
  };

  // Call updateSelectedTab function on component mount and when the URL changes
  useEffect(() => {
    updateSelectedTab();
    window.addEventListener('popstate', updateSelectedTab);
    return () => {
      window.removeEventListener('popstate', updateSelectedTab);
    };
  }, []);

  const navToggle = () => {
    active === 'nav__menu' ? setActive('nav__menu nav__active') : setActive('nav__menu');

    toggleIcon === 'nav__toggler'
      ? setToggleIcon('nav__toggler toggle')
      : setToggleIcon('nav__toggler');
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      // Make sure searchQuery is not empty
      if (selectedTab == 'search_results') {
        setSelectedTab('all');
      }
      const searchURL = `${searchBaseURL}/${selectedTab}/${encodeURIComponent(searchQuery)}`;
      console.log(searchURL);
      history('search_results', { state: searchURL });
      setSearchOpen(!searchOpen);
    }
  };

  return (
    <div>
      <div>
        <nav className="nav">
          <a href="/" className="nav__logo">
            <img src={transparent}></img>
          </a>
          <ul className={active}>
            <li className="nav_menu">
              <a href="/" className="nav__link">
                {' '}
                Home{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/about" className="nav__link">
                {' '}
                About{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/housing" className="nav__link">
                {' '}
                Housing{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/education" className="nav__link">
                {' '}
                Education and Employment{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/healthcare" className="nav__link">
                {' '}
                Healthcare{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/visualizations" className="nav__link">
                {' '}
                Visualizations{' '}
              </a>
            </li>
            <li className="nav_menu">
              <a href="/developervisualizations" className="nav__link">
                {' '}
                Developer Visualizations{' '}
              </a>
            </li>
          </ul>
          <div
            className="search-icon"
            onClick={toggleSearch}
            onMouseEnter={() => setSearchHovered(true)}
            onMouseLeave={() => setSearchHovered(false)}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              cursor: searchHovered ? 'pointer' : 'auto'
            }}>
            <div className="nav__link" style={{ color: theme.colors.light_text, paddingRight: 10 }}>
              {' '}
              Search{' '}
            </div>
            <img src={search_icon} style={{ width: 15, height: 15 }} />
          </div>
          <div onClick={navToggle} className={toggleIcon}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </div>
      <div className="search-popup-container">
        {searchOpen && (
          <div className="search-popup">
            <Input
              type="text"
              aria-label="Demo input"
              placeholder={'Search ' + selectedTab + ' instances'}
              className="custom-input"
              style={{
                backgroundColor: theme.colors.light_text,
                color: theme.colors.background_brown2,
                width: '100%',
                height: '40px',
                fontSize: '14px',
                fontFamily: fonts.fonts.subheader,
                padding: '8px'
              }}
              value={searchQuery}
              onChange={handleSearchInputChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
