import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import theme from '../styles/theme';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import fonts from '../styles/fonts';



type Dictionary<T> = {
  [key: string]: T;
};

type ChildData = {
  name: string;
  num_children_insecure: number;
};

type CountyData = {
  name: string;
  num_people_insecure: number;
  total_population: number;
};

type MealData = {
  cost_per_meal: number;
};


const DeveloperVisualizations: React.FC = () => {
  const childRef = useRef<SVGSVGElement>(null);
  const [childrenData, setChildData] = useState<Dictionary<number>>({});

  const peopleRef = useRef<SVGSVGElement>(null);
  const [peopleData, setPeopleData] = useState<Dictionary<CountyData>>({});

  const costRef = useRef<SVGSVGElement>(null);
  const [mealCostData, setMealCostData] = useState<number[]>([]);


  const baseURL = 'https://api.texasfoodinsecurity.me/';

  useEffect(() => {
    const fetchChildrenData = async () => {
      try {
        const response = await axios.get(baseURL + 'county/all');
        const countyData: ChildData[] = response.data.county;

        // Sort county data by the number of insecure children (descending)
        countyData.sort((a, b) => b.num_children_insecure - a.num_children_insecure);

        // Get the top 10 counties based on the number of insecure children
        const topCounties = countyData.slice(0, 10);

        // Create dictionary of top 10 counties with name as key and count as value
        const topCountiesData: Dictionary<number> = {};
        topCounties.forEach(county => {
          topCountiesData[county.name] = county.num_children_insecure;
        });

        // Set the state with top 10 counties data
        setChildData(topCountiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    const fetchPeopleData = async () => {
      try {
        const response = await axios.get(baseURL + 'county/all');
        const pData: CountyData[] = response.data.county;

        const peopleDataDict: Dictionary<CountyData> = {};
        pData.forEach(county => {
          peopleDataDict[county.name] = {
            name: county.name,
            num_people_insecure: county.num_people_insecure,
            total_population: county.total_population
          };
        });

        setPeopleData(peopleDataDict);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchMealCostData = async () => {
      try {
        const response = await axios.get(baseURL + 'county/all');
        const costData: MealData[] = response.data.county;

        const mealCosts = costData.map(county => county.cost_per_meal);
        setMealCostData(mealCosts);
      } catch (error) {
        console.error('Error fetching meal cost data:', error);
      }
    };


    fetchChildrenData();
    fetchPeopleData();
    fetchMealCostData();
  }, []);

  useEffect(() => {
    if (!childRef.current || Object.keys(childrenData).length === 0) return;

    const margin = { top: 50, right: 50, bottom: 50, left: 100 };
    const width = 800 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3.select(childRef.current);

    svg.selectAll('*').remove();

    const y = d3.scaleBand()
      .domain(Object.keys(childrenData))
      .range([0, height])
      .padding(0.1);

    const x = d3.scaleLinear()
      .domain([0, d3.max(Object.values(childrenData)) || 0])
      .nice()
      .range([0, width]);

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    const chartGroup = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Render x-axis
    chartGroup.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    // Render y-axis
    chartGroup.append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    // Render bars
    chartGroup.selectAll('.bar')
      .data(Object.entries(childrenData) as [string, number][])
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('y', d => y(d[0]) || 0) // Use y scale for county names (y-axis)
      .attr('x', 0) // Bars start from the beginning of x-axis
      .attr('width', d => x(d[1])) // Use x scale for count values (x-axis)
      .attr('height', y.bandwidth()) // Bar height based on bandwidth of y scale
      .attr('fill', 'purple');

    // Label axes and title
    svg.append('text')
      .attr('x', width / 2 + margin.right)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '18px')
      .text('Top 10 Counties with Highest Child Food Insecurity');

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2 - margin.top)
      .attr('y', margin.left / 4)
      .attr('text-anchor', 'middle')
      .attr('font-size', '14px')
      .text('County');

  }, [childrenData]);


  useEffect(() => {
    if (!peopleRef.current || Object.keys(peopleData).length === 0) return;

    const margin = { top: 50, right: 50, bottom: 50, left: 70 };
    const width = 800 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const svg = d3.select(peopleRef.current);

    svg.selectAll('*').remove();

    // Extract total population and num_people_insecure data for scaling
    const populations = Object.values(peopleData).map(d => d.total_population);
    const insecureCounts = Object.values(peopleData).map(d => d.num_people_insecure);

    const x = d3.scaleLinear()
      .domain([0, d3.max(populations) || 0])
      .nice()
      .range([margin.left, width - margin.right]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(insecureCounts) || 0])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    svg.append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    svg.append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis);

    svg.selectAll('.dot')
      .data(Object.entries(peopleData))
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(d[1].total_population)) // X-coordinate is total_population
      .attr('cy', d => y(d[1].num_people_insecure)) // Y-coordinate is num_people_insecure
      .attr('r', 5)
      .style('fill', 'red')
      .style('opacity', 0.7);

    svg.append('text')
      .attr('x', width / 2)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .style('font-size', '18px')
      .text('Food Insecurity of People compared to Total Population');

    // Adding labels to axes
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', width / 2)
      .attr('y', height - 10)
      .text('Total Population');

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', 20)
      .text('Number of Food-Insecure People');
  }, [peopleData]);

  useEffect(() => {
    // Ensure valid data exists
    if (!mealCostData || mealCostData.length === 0) return;

    // Set up SVG dimensions and margins
    const margin = { top: 30, right: 30, bottom: 50, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Append SVG element to the component's ref
    const svg = d3.select(costRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    

    // Filter out NaN values and sort the data
    const dataSorted = mealCostData.filter(d => !isNaN(d)).sort((a, b) => a - b);
    if (dataSorted.length === 0) return; // Return if no valid data points

    // Compute quartiles and other statistics
    const q1 = d3.quantile(dataSorted, 0.25);
    const median = d3.quantile(dataSorted, 0.5);
    const q3 = d3.quantile(dataSorted, 0.75);
    if(!q1 || !q3){
      return;
    }
    const interQuantileRange = q3 - q1;
    const min = q1 - 1.5 * interQuantileRange;
    const max = q3 + 1.5 * interQuantileRange;

    // Define the Y scale
    const y = d3.scaleLinear()
      .domain([2, d3.max(dataSorted) || 24]) // Adjust domain based on your data range
      .range([height, 0]);

    // Draw Y axis with label
    svg.append('g')
      .call(d3.axisLeft(y))
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${-margin.left + 20}, ${height / 2}) rotate(-90)`)
      .text('Cost ($)');

    // Draw the box plot components
    const center = width/2;
    const boxWidth = 100;
    
    // Main vertical line
    svg.append('line')
      .attr('x1', center)
      .attr('x2', center)
      .attr('y1', y(min) || 0)
      .attr('y2', y(max) || 0)
      .attr('stroke', 'black');

    // Box
    svg.append('rect')
      .attr('x', center - boxWidth / 2)
      .attr('y', y(q3) || 0)
      .attr('width', boxWidth)
      .attr('height', (y(q1) || 0) - (y(q3) || 0))
      .attr('stroke', 'black')
      .style('fill', '#69b3a2');

    // Median line
    svg.selectAll('.horizontal-line')
      .data([min, median, max].filter(d => !isNaN(d || 0)))
      .enter()
      .append('line')
      .attr('class', 'horizontal-line')
      .attr('x1', center - boxWidth / 2)
      .attr('x2', center + boxWidth / 2)
      .attr('y1', d => y(d || 0) || 0)
      .attr('y2', d => y(d || 0) || 0)
      .attr('stroke', 'black');

    // Add title
    svg.append('text')
      .attr('x', width / 2)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '16px')
      .text('Average Cost per Meal');

  }, [mealCostData]);




  return (
    <div className="model__page">
      <h1 className="healthcare__header"  style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20}}>Developer Visualizations</h1>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', alignItems: 'center', height: '50rem' }}>
        <svg ref={childRef} width={800} height={500}></svg>
      </div>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', alignItems: 'center', height: '50rem' }}>
        <svg ref={peopleRef} width={800} height={500}></svg>
      </div>
      <div style={{ display: 'flex', fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2, justifyContent: 'center', alignItems: 'center', height: '50rem' }}>
        <svg ref={costRef} width={800} height={500}></svg>
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <h1 className="healthcare__header" style={{ fontFamily: fonts.fonts.header1, color: theme.colors.primary_green, fontStyle: "italic", marginLeft: 20 }}>
          Developer Critiques
        </h1>
      </div>
      <Card sx={{ minWidth: 500, maxWidth: 500, backgroundColor: theme.colors.background_brown2, margin: 'auto' }}>
  <CardContent>
    <Typography
      gutterBottom
      variant="h4"
      component="div"
      color={theme.colors.light_text2}
      fontFamily={fonts.fonts.subheader}
      textAlign="center"
    >
      <summary style={{ textDecoration: 'underline' }}>What did they do well?</summary>
      <summary>Not only did they add some extra features but they were also very creative with those features. They did really well with their dark mode feature and their english to spanish translation feature.</summary>
      <summary style={{ textDecoration: 'underline' }}>How effective was their RESTful API?</summary>
      <summary>Their Restful API was pretty straightforward to understand and hence effective by simplicity.</summary>
      <summary style={{ textDecoration: 'underline' }}>How well did they implement your user stories?</summary>
      <summary>They did a pretty good job with implementing our user stories and taking our suggestions into consideration. One of the things we told them to improve on is having the dark mode feature on all their pages instead of just the main one.</summary>
      <summary style={{ textDecoration: 'underline' }}>What did we learn from their website?</summary>
      <summary>Their website was really helpful in educating people about different ways to tackle food insecurity and how there are multiple resources out there to help with that.</summary>
      <summary style={{ textDecoration: 'underline' }}>What can they do better?</summary>
      <summary>The way they chose to display the images of their cards kind of ruined the aesthetic of their model pages so that is something they can improve on.</summary>
      <summary style={{ textDecoration: 'underline' }}>What puzzles us about their website?</summary>
      <summary>We wonder if it would help the effectiveness of their website if they did not just mention a general grocery store like HEB, but actually listed specific HEB products that can help with their consumers food insecurity problem.</summary>
    </Typography>
  </CardContent>
</Card>
    </div>
  );
};

export default DeveloperVisualizations;
