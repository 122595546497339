import React, { useState, useEffect } from 'react';
import '../styles/About.css';
import '../styles/Text.css';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import axios from 'axios';
import DeveloperCard from '../components/DeveloperCard';
import ToolCard from '../components/ToolCard';
import { contributors, text, tools, data_sources } from '../data/About_data';

interface contributorProps {
  email: string;
  commits: number;
}

interface author {
  username: string;
}

interface IssueData {
  id: number;
  title: string;
  description: string;
  author: author;
  // Add other properties as needed
}

function About() {
  const [contributorInfo, setContributorInfo] = useState([]);
  const [issueInfo, setIssueInfo] = useState<IssueData[]>([]);

  const projectId = 54622060;
  const gitLabToken = 'glpat-o2Cwd-51y9-p5uLDsDAf';

  useEffect(() => {
    // Fetch commit data
    axios
      .get(`https://gitlab.com/api/v4/projects/${projectId}/repository/contributors`, {
        headers: {
          'PRIVATE-TOKEN': gitLabToken
        }
      })
      .then((response) => {
        setContributorInfo(response.data);
        console.log(response.data);
      });

    // Fetch issue data
    // axios
    //   .get(`https://gitlab.com/api/v4/projects/${projectId}/issues/?per_page=100`, {
    //     headers: {
    //       'PRIVATE-TOKEN': gitLabToken
    //     }
    //   })
    //   .then((response) => {
    //     setIssueInfo(response.data);
    //     console.log(response.data);
    //   });

    const fetchIssues = async () => {
      let page = 1;
      let allIssues: IssueData[] = [];
      let totalPages = 1;

      while (page <= totalPages) {
        const response = await axios.get(
          `https://gitlab.com/api/v4/projects/${projectId}/issues/?per_page=100&page=${page}`,
          {
            headers: {
              'PRIVATE-TOKEN': gitLabToken,
            },
          }
        );

        allIssues = [...allIssues, ...response.data];
        totalPages = parseInt(response.headers['x-total-pages'], 10);
        page++;
      }

      setIssueInfo(allIssues);
    };

    fetchIssues();
  }, [gitLabToken, projectId]);

  return (
    <div className="about__page">
      <div className="about__section">
        <h1 className="about__header"  style={{ fontFamily: fonts.fonts.header1 , color: theme.colors.primary_green, fontStyle: "italic"}}>About Us</h1>
        <p className="about__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2}}>{text[0].mission_statement}</p>
        <p className="about__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2}}>{text[0].intended_users}</p>
        <p className="about__text" style={{ fontFamily: fonts.fonts.subheader , color: theme.colors.background_brown2}}>{text[0].disparate_data}</p>
      </div>

      <div className="about__section" style={{backgroundColor: theme.colors.background_brown2}}>
        <h1 className="about__header" style={{ fontFamily: fonts.fonts.header1, color: theme.colors.light_text, fontStyle: "italic" }}>Meet the Team!</h1>
        <div className="about__cards">
          <div className="row">
            {contributors.map((contributor, index) => {
              let numCommits = 0;
              contributor.email.forEach((email) => {
                // Filter contributors from API data matching the current email
                const contributors_filtered: contributorProps[] = contributorInfo.filter(
                  (contributor_from_api: contributorProps) => contributor_from_api.email.includes(email)
                );
                
                // Sum up the commits for each email address
                if (contributors_filtered.length > 0) {
                  contributors_filtered.forEach((filteredContributor) => {
                    numCommits += filteredContributor.commits;
                  });
                }
              });
              
              const numIssues = issueInfo.filter(
                (issue: IssueData) => (issue.author as { username: string }).username === contributor.gitlab_username
              ).length;

              return (
                <DeveloperCard
                  key={index}
                  img_src={contributor.img_src}
                  name={contributor.name}
                  description={contributor.description}
                  role={contributor.role}
                  tests={contributor.tests}
                  commits={numCommits}
                  issues={numIssues}
                />
              );
            })}
          </div>
        </div>
        <h1 className="about__header" style={{ color: theme.colors.light_text, fontFamily: fonts.fonts.header1, fontStyle: "italic" }}>Totals</h1>
        <h4 className="about__header2" style={{ color: theme.colors.light_text, fontFamily: fonts.fonts.subheader }}>
          Total commits:{' '}
          {contributorInfo.reduce(
            (total, contributor: contributorProps) => total + contributor.commits,
            0
          )}
        </h4>
        <h4 className="about__header2" style={{ color: theme.colors.light_text, fontFamily: fonts.fonts.subheader }}>Total issues: {issueInfo.length}</h4>
      </div>
      <div className="about__section">
        <h1 className="about__header" style={{ color: theme.colors.primary_green, fontFamily: fonts.fonts.header1, fontStyle: "italic" }}>Data Sources</h1>
        <div className="about__datasource" style={{ backgroundColor: theme.colors.primary_green}}>
          {data_sources.map((source) => (
            <h5 key={source.name}>
              <a style={{ color: theme.colors.light_text2, textDecoration: 'underline', fontFamily: fonts.fonts.subheader }} href={source.link}>
                {source.name}
              </a>
              <p style={{ color: theme.colors.light_text, fontFamily: fonts.fonts.subheader }}> {source.description} </p>
            </h5>
          ))}
        </div>
      </div>
      <div className="about__section">
        <h1 className="about__header" style={{ color: theme.colors.primary_green, fontFamily: fonts.fonts.header1, fontStyle: "italic" }}>Tools Used</h1>
        <div className="about__cards">
          {tools.map((tool, index) => (
            <ToolCard key={index} {...tool} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
