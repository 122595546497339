import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import theme from '../styles/theme';
import fonts from '../styles/fonts';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import HousingCard from '../components/HousingCard';
import HealthcareCard from '../components/HealthcareCard';
import EducationCard from '../components/EducationCard';

import AllIcon from '@mui/icons-material/MilitaryTech';
import HomeIcon from '@mui/icons-material/Home';
import EducationIcon from '@mui/icons-material/School';
import HealthIcon from '@mui/icons-material/HealthAndSafety';


import '../styles/Card.css';

type DataItem = string | number;
type DataRecord = Record<string, DataItem>;

function determineCardType(index: number, data: DataRecord, searchTerm: string): JSX.Element {
  const dataKeys = Object.keys(data);

  if (!dataKeys.includes('related_housing_id')) {
    return (
      <HousingCard
        key={index}
        id={data.instance as number}
        searchTerm={searchTerm}
        instance={data.instance as number}
        name={data.name as string}
        img_src={data.img_src as string}
        description={data.description as string}
        city_id={data.city_id as number}
        address={data.address as string}
        phone={data.phone as string}
        hours={data.hours as string}
        eligibility_requirements={data.eligibility_requirements as string}
        areas_served={data.areas_served as string}
        link={data.website_link as string}
        related_healthcare_id={data.related_healthcare_id as number}
        related_education_id={data.related_education_id as number}
      />
    );
  } else if (!dataKeys.includes('related_healthcare_id')) {
    return (
      <HealthcareCard
        key={index}
        id={data.instance as number}
        searchTerm={searchTerm}
        instance={data.instance as number}
        name={data.name as string}
        img_src={data.img_src as string}
        description={data.description as string}
        city_id={data.city_id as number}
        address={data.address as string}
        phone={data.phone as string}
        assistance_type={data.assistance_type as string}
        facility_type={data.facility_type as string}
        vid_src={data.vid_src as string}
        website={data.website as string}
        related_housing_id={data.related_housing_id as number}
        related_education_id={data.related_education_id as number}
      />
    );
  } else if (!dataKeys.includes('related_education_id')) {
    return (
      <EducationCard
        key={index}
        id={data.instance as number}
        searchTerm={searchTerm}
        instance={data.instance as number}
        name={data.name as string}
        img_src={data.img_src as string}
        city_id={data.city_id as number}
        contribution={data.contribution as string}
        degree={data.degree as string}
        distance_learning={data.distance_learning_offered as string}
        funding={data.funding as string}
        address={data.address as string}
        link={data.link as string}
        related_housing_id={data.related_housing_id as number}
        related_healthcare_id={data.related_healthcare_id as number}
      />
    );
  } else {
    // If none of the interfaces match, you can handle it accordingly
    return <div>No matching card component found</div>;
  }
}

function isHousingData(data: Record<string, DataItem>): boolean {
  const dataKeys = Object.keys(data);
  return !dataKeys.includes('related_housing_id');
}

function isEducationData(data: Record<string, DataItem>): boolean {
  const dataKeys = Object.keys(data);
  return !dataKeys.includes('related_education_id');
}

function isHealthcareData(data: Record<string, DataItem>): boolean {
  const dataKeys = Object.keys(data);
  return !dataKeys.includes('related_healthcare_id');
}

function SearchResults() {
  const location = useLocation();
  const url = location.state;
  const urlParts = url.split('/');
  const searchTerm = decodeURIComponent(urlParts[urlParts.length - 1]); 
  const searchArea = decodeURIComponent(urlParts[urlParts.length - 2]); 
  const formattedSearchTerm = `"${searchTerm}"`;

  const [data, setData] = useState<Record<string, DataItem>[] | null>(null);
  const [page, setPage] = useState<number[]>([1, 1, 1, 1]);

  const [tabValue, setTabValue] = React.useState(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage([1, 1, 1, 1]);
  };
  const itemsPerPage = 9;

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        console.log(response.data?.search);
        setData(null);
        setData(response.data?.search);
      })
      .catch((error) => {
        console.error('Error fetching housing data:', error);
      });
  }, [url]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const newPages = [...page];
    newPages[tabValue] = value;
    setPage(newPages);
  };

  const housingData = data ? data.filter(item => isHousingData(item)) : [];
  const educationData = data ? data.filter(item => isEducationData(item)) : [];
  const healthcareData = data ? data.filter(item => isHealthcareData(item)) : [];


  const startIndex = (page[tabValue] - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageData = data ? data.slice(startIndex, endIndex) : [];

  const housingPage = data ? housingData.slice(startIndex, endIndex) : [];
  const educationPage = data ? educationData.slice(startIndex, endIndex) : [];
  const healthcarePage = data ? healthcareData.slice(startIndex, endIndex) : [];
  const tabCounts = [data, housingData, educationData, healthcareData].map(data => Math.ceil((data ? data.length : 0) / itemsPerPage));

  return (
    <div style={{backgroundColor: theme.colors.background_beige, minHeight: 800}}>
      <div className="sort__container">
        <div
          className="sort__container"
          style={{
            backgroundColor: theme.colors.background_beige,
            fontFamily: fonts.fonts.subheader,
            color: theme.colors.background_brown2,
            fontWeight: 900
          }}>
          {' '}
          Total search results for {formattedSearchTerm}: {data?.length}
        </div>
      </div>
      {searchArea === "all" ? (
      <Tabs 
        value={tabValue} 
        onChange={handleChange} 
        style={{ color: theme.colors.primary_green }}
        centered>
        <Tab icon={<AllIcon fontSize='large'/>} label="ALL" style={{ color: theme.colors.primary_green, fontSize: 15, fontFamily: fonts.fonts.subheader, fontWeight: 900 }}/>
        <Tab icon={<HomeIcon fontSize='large'/>} label="HOUSING" style={{ color: theme.colors.primary_green, fontSize: 15, fontFamily: fonts.fonts.subheader, fontWeight: 900 }}/>
        <Tab icon={<EducationIcon fontSize='large'/>} label="EDUCATION AND EMPLOYMENT" style={{ color: theme.colors.primary_green, fontSize: 15, fontFamily: fonts.fonts.subheader, fontWeight: 900 }}/>
        <Tab icon={<HealthIcon fontSize='large'/>} label="HEALTHCARE" style={{ color: theme.colors.primary_green, fontSize: 15, fontFamily: fonts.fonts.subheader, fontWeight: 900 }}/>
      </Tabs>
      ) : null}
      <div>
        <div className="card__container">
          {(tabValue === 0 && pageData.map((item: Record<string, DataItem>, index: number) => (
            <div key={index}>{determineCardType(index, item, searchTerm)}</div>
          ))) ||
            (tabValue === 1 && housingPage.map((item: Record<string, DataItem>, index: number) => (
              <div key={index}>{determineCardType(index, item, searchTerm)}</div>
            ))) ||
            (tabValue === 2 && educationPage.map((item: Record<string, DataItem>, index: number) => (
              <div key={index}>{determineCardType(index, item, searchTerm)}</div>
            ))) ||
            (tabValue === 3 && healthcarePage.map((item: Record<string, DataItem>, index: number) => (
              <div key={index}>{determineCardType(index, item, searchTerm)}</div>
            )))
          }
        </div>
        <div className="pagination__container">
        <Stack spacing={2}>
          <Pagination
            count={tabCounts[tabValue]}
            page={page[tabValue]}
            onChange={handlePageChange}
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: '1.5rem',
                minWidth: '30px',
                minHeight: '30px',
                margin: '0 5px',
              },
              '& .MuiPaginationItem-ellipsis': {
                fontSize: '2rem',
              },
            }}
          />
        </Stack>
      </div>
      </div>
    </div>
  );
}

export default SearchResults;
