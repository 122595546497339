import React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
// import { SelectChangeEvent } from '@mui/material';
import fonts from '../styles/fonts';
import theme from '../styles/theme';

interface Option {
  label: string;
  items: string[];
}

interface DropDownMenuProps {
  options: Option;
  onFilterSelection: (label: string, selectedFilters: string[]) => void;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ options, onFilterSelection }) => {
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFilters(prevFilters => [...prevFilters, value]);
    } else {
      setSelectedFilters(prevFilters => prevFilters.filter(filter => filter !== value));
    }
  };

  const handleApplyFilters = () => {
    onFilterSelection(options.label, selectedFilters);
  };

  const handleResetFilters = () => {
    setSelectedFilters([]);
    onFilterSelection(options.label, []);
  };

  return (
    <div style={{ width: '200px' }}>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <InputLabel id={`multiple-select-label-${options.label}`} style={{ fontFamily: fonts.fonts.subheader, color: theme.colors.primary_green, fontWeight: 900}}>{options.label}</InputLabel>
        <Select
          style={{fontFamily: fonts.fonts.subheader, color: theme.colors.primary_green, fontWeight: 900}}
          labelId={`multiple-select-label-${options.label}`}
          id={`multiple-select-${options.label}`}
          multiple
          value={selectedFilters}
          onChange={() => {}} // No need for onChange for Select
          renderValue={(selected) => (selected as string[]).join(', ')}
        >
          <Button onClick={handleApplyFilters} style={{ marginTop: 10, marginLeft: 30, fontSize: "12px" }}>Apply</Button>
          <Button onClick={handleResetFilters} style={{ marginTop: 10, fontSize: "12px" }}>Reset</Button>
          {options.items.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Checkbox
                checked={selectedFilters.includes(item)}
                onChange={handleCheckboxChange}
                value={item}
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDownMenu;
