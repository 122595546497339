import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../styles/InstancePage.css';
import MapComponent from '../components/MapComponent';
import fonts from '../styles/fonts';
import theme from '../styles/theme';

type paramType = {
  index: string;
};

interface EducationData {
  id: number;
  name: string;
  instance: number;
  img_src: string;
  description: string;
  city_id: number;
  contribution: string;
  degree: string;
  distance_learning_offered: string;
  funding: string;
  address: string;
  resource_type: string;
  link: string;
  latitude: string;
  longitude: string;
  vid_src: string;
  related_housing_id: number;
  related_healthcare_id: number;
}

const baseURL = 'https://api.veteranhaven.me/education';
const cityBaseURL = 'https://api.veteranhaven.me/cities';

const defaultDescription = "This opportunity allows for veterans to commit to their education and opens up opportunities for their future career growth."

export default function EducationInstance() {
  const { index } = useParams<paramType>();

  const [educationData, setEducationData] = useState<EducationData | null>(null);
  const [cityName, setCityName] = useState<string | null>(null);
  const [imgName, setImgName] = React.useState('');

  useEffect(() => {
    axios
      .get(baseURL + '/' + index)
      .then((response) => {
        import(`../assets/education_instance_images/${response.data.img_src}`).then((img) => {
            setImgName(img.default);
            setEducationData(response.data);
            }).catch((error) => {
              console.error('Error importing image:', error);
          });
        axios
          .get(cityBaseURL + '/' + response.data.city_id)
          .then((cityResponse) => {
            setCityName(cityResponse.data.city_name);
          })
          .catch((cityError) => {
            console.error('Error fetching city data:', cityError);
          });
      })
      .catch((error) => {
        console.error('Error fetching education data:', error);
      });
  }, []);

  return (
    <div className="instance__page">
      <img className="instance__img" src={imgName}></img>
      <div className="instance__section" style={{ paddingBottom: '100px' }}>
        <div className="text__container">
          <Typography
            fontWeight="bold"
            paddingTop="1.5rem"
            gutterBottom
            variant="h1"
            fontSize="5rem"
            component="div"
            color={theme.colors.primary_green}
            fontFamily={fonts.fonts.header1}
          >
            {educationData?.name} 
           
          </Typography>
          <Typography
            variant="h3"
            color="#7C826B"
            textAlign="left"
            fontFamily={fonts.fonts.subheader}>
            {defaultDescription}
          </Typography>
          <Typography
            fontSize="3.5rem"
            color={theme.colors.background_brown2}
            fontFamily={fonts.fonts.header1}
            fontStyle="italic">
            Details:
          </Typography>
          <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>City Name:</span> {cityName}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Address:</span> {educationData?.address}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Degree Type:</span> {educationData?.degree}
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Resource Type:</span> Education
        </Typography>

        <Typography
          variant="h4"
          color={theme.colors.background_brown2}
          fontFamily={fonts.fonts.subheader}>
          <span style={{ fontWeight: 'bold' }}>Website:</span>{' '}
          {educationData?.link !== "" ? educationData?.link : "Website not available."}
        </Typography>

        {educationData && (
          <Typography
            variant="h4"
            color={theme.colors.background_brown2}
            fontFamily={fonts.fonts.subheader}>
            {isNaN(parseInt(educationData.funding)) ? (
              <span style={{ fontWeight: 'bold' }}>Funding Available For {educationData.funding}</span>
            ) : (
              <span style={{ fontWeight: 'bold' }}>Funding Available for {parseInt(educationData.funding)} students</span>
            )}
          </Typography>
        )}
        </div>
        <div className="card__container">
        <Card sx={{ width: 500, backgroundColor: '#7c826b' }}>
            <CardContent>
              <div style={{ width: '100%', height: '400px' }}>
                {educationData && (
                  <MapComponent latitude={educationData?.latitude} longitude={educationData?.longitude}/>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="footer">
        <Link to={`/housing/${educationData?.related_housing_id}`}>
          {' '}
          {cityName} Housing Resources{' '}
        </Link>{' '}
        |
        <Link to={`/healthcare/${educationData?.related_healthcare_id}`}>
          {' '}
          {cityName} Health Care Resources
        </Link>
      </div>
    </div>
  );
}
